import React from 'react'
import 'bulma/css/bulma.min.css'

const FrenchLearnersLandingPage = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="hero is-fullheight"
        style={{
          backgroundImage: `url(/assets/images/community2.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            zIndex: 1,
          }}
        ></div>
        <div className="hero-body" style={{position: 'relative', zIndex: 2}}>
          <div className="container has-text-centered">
            <h1
              className="title is-1 has-text-white"
              style={{marginBottom: '1rem'}}
            >
              Únete a la Comunidad de Aprendices de Francés
            </h1>
            <h2
              className="subtitle is-3 has-text-white"
              style={{marginBottom: '2rem'}}
            >
              Conecta con otros hispanohablantes y aprende francés de forma
              interactiva y divertida.
            </h2>
            <a
              href="https://www.facebook.com/share/g/WrCLrkFvDm5fCkNQ/"
              target="_blank"
              rel="noopener noreferrer"
              className="button is-large is-warning"
              style={{marginBottom: '2rem'}}
            >
              Más Información
            </a>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FrenchLearnersLandingPage
